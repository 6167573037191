$header-font: 'SportingGrotesqueGras';
$white: #f6f6f6;
$black: #000000;
$red: #b33f4c;
$backgroundColor: $white;
$bodyColor: $black;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.5rem;
$spacing-6: 2rem;

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
}

* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

a:hover {
	font-style: italic;
}

a, p {
	color: black;
	letter-spacing: 1px;
	font-family: sans-serif;
	line-height: 1.33rem;
}

body {
	margin: 0;
}

@media screen and (min-width: 320px) {
	html {
		font-size: calc(16px + 6 * ((100vw - 320px) / 680));
	}
}

@media screen and (min-width: 1000px) {
	html {
		font-size: 22px;
	}
}

#myVideo {
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

iframe#myVideo {
	height: 100%;
	width: 100%;
	border: none;
	display: block;
}

.header-font {
	font-family: $header-font;
}

.description {
	padding: 0 3rem;
    line-height: 1.5;
    text-transform: uppercase;
    font-size: 0.75rem;
}
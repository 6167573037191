@import "main";

$border-width: 1px;
$cell-height: 15vh;
$hl-color: #f24a3c;

// TOP
body {
	background-color: $white;
}

.wrapper {
	margin: 0 auto;
	max-width: 1024px;
}

section {
	display: flex;
	flex: 1;
}


// SECTION HEADER
.header {
	flex-direction: column;
	justify-content: start;
	align-items: center;

	text-align: center;
	height: 100vh;
}

.header-nav {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    &:nth-of-type(1) {
	    animation: fade-in-right 1s ease-out both;
    }
    &:nth-of-type(2) {
	    animation: fade-in-right 1.5s ease-out both;
    }
    &:nth-of-type(3) {
	    animation: fade-in-right 2s ease-out both;
    }

	&:not(:last-child) {
    	margin-bottom: 0.5rem;
	}

    .header-nav-item {
	    padding-top: 0.75rem;
	    padding-bottom: 0.75rem;
	    background-color: $black;
	    width: 100%;
	    color: $white;
    	font-size: 1.15rem;
    	display: inline-block;
		font-family: $header-font;

    }
}

.logo {
	margin-top: 1rem;
	margin-bottom: 1rem;
	height: 40%;
}

h1 {
	font-family: $header-font;
	text-transform: uppercase;
	font-size: 5rem;	
	margin-bottom: 2rem;
	margin-top: 2rem;
}

.subtitle {
	font-family: serif;
	margin: 0;
	letter-spacing: 1.25rem;
	text-transform: uppercase;
	font-style: italic; 
}


// SECTION MAIN
.wrapper-main {
	margin: 0 auto;
	max-width: 1280px;
}

main {
	margin-top: 5rem;
	margin-bottom: 5rem;
	display: flex;
	flex-wrap: wrap;
}


#magazine {
	padding-top: 5rem;

	h3 {
		background-color: $black;
		color: $white;
		padding: 0.5rem;
	}
}
// STORY TILE STUFF
.scroll-container{
	display: flex;
	flex-flow: wrap;

	margin-bottom: 2rem;
	padding: 1rem 0;

	flex: 1;
	justify-content: center;
}

.scroll-container:nth-child(even) {
	background-color: black;

	.tile-image {
		background-blend-mode: difference;
	}
	.tile {
		background-color: black;
		color: $white;
	}
}


a.tile {
	width: 260px;
	position: relative;
	background-color: $white;
	text-align: center;
	border: none;
	margin: 1.75rem;
	margin-bottom: 7rem;
}

.tile-image {
	width: 100vw;
	max-width: 220px; 
	height: 15vh;
	background-position: center center;
	z-index: 1;
}

.tile-title {
	position: relative;

	font-family: sans-serif;
	font-weight: bold;
	text-transform: uppercase;

	letter-spacing: 0.5rem;
	font-size: 1rem;

	margin-top: 1.5rem;

}

.tile-byline {
	margin-top: 1rem;
	font-size: 0.75rem;
	letter-spacing: 0.15rem;
	font-style: italic;
}

.tile-tags {
	position: absolute;
	top: 0;
	right: 0;

	z-index: 2;

	font-size: 0.75rem;
	font-family: sans-serif;
	font-weight: bold;
	text-transform: uppercase;

	color: black;
	background-color: $white;
                
}

// TILE IMAGES
.tile-image {
	background-size: cover;
	background-color: $white;
	background-blend-mode: darken;
}
.infinity .tile-image {
	background-image: url(../images/infinity.jpg);
}
.important .tile-image {
	background-image: url(../images/important.jpg);
}
.lets-go .tile-image {
	background-image: url(../images/else.jpg);
}
.just-items .tile-image {
	background-image: url(../images/items.jpg);
}
.texture .tile-image {
	background-image: url(../images/texture.jpg);
}
.leaf .tile-image {
	background-image: url(../images/leaf.jpg);
}
.whole-life .tile-image {
	background-image: url(../images/whole-life.jpg);
	background-size: contain;
	background-repeat: no-repeat;
}
.walgreens .tile-image {
	background-image: url(../images/walgreens.jpg);
}
.force-fed .tile-image {
	background-image: url(../images/force-fed.jpg);
	background-size: contain;
}
.borges-dwelling .tile-image {
	background-image: url(../images/borges.jpg);
	background-size: contain;
	background-blend-mode: inherit;
}
.asemic-poems .tile-image {
	background-image: url(../images/asemic-poems.png);
	background-size: contain;
}
.melts-down .tile-image {
	background-image: url(../images/melts-01.png);
	background-size: contain;
}
.blossom .tile-image {
	background-image: url(../images/blossom.png);
	background-size: contain;
	background-repeat: no-repeat;
}

// ABOUT
.section-header {
	align-items: center;

	h3 {
		font-family: $header-font;
		text-transform: uppercase;
	    font-size: 2rem;
	    font-weight: normal;
	    margin: 0;
	    margin-bottom: 0px;
	    letter-spacing: 3px;
	}
}

.feed-link img {
	width: 25px;
}

.about, .subscribe {
	flex-direction: column;
	background-color: black;
	color: $white;
	padding: 1rem;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 1px solid black;

	.separator {
		color: black;
		line-height: 0;
	}

	p {
		color: $white;
		line-height: 1rem;
	}

}

.subscribe h3 {
	font-size: 2rem;
}

.section-content {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	// height: 200vh;
	height: 80vh;
	justify-content: space-between;

	a {
		color: white;
		font-family: $header-font;
	}

}

.email, .header-font {
	font-family: $header-font;
}

form {
	background-color: black;
	font-size: 2rem;
	color: white;
}

input {
	color: black;
	width: 25vw;
	height: 1.5rem;
}

.inverted-container {
	background-color: $black;
	color: $white;
	> p, a {
		color: $white;
	}
}
